<template>
	<div>
		<div class="champ-nav-wrapper">
			<div class="champ-nav-item champ-nav-item-selectable">
				<h3><span class="dd-btn-txt">Champion</span></h3>
				<button class="dd-btn dd-btn-selectable">
					<span class="dd-btn-text">*Champ*</span>
					<span class="dd-span"></span>
				</button>
				<div class="champ-nav-dd-content" id="dd-champ-div">
					<a href="#">All</a>
					<a href="#">*Champ*</a>
					<a href="#">*Champ*</a>
					<a href="#">*Champ*</a>
				</div>
			</div>
			<div class="champ-nav-item champ-nav-item-selectable">
				<h3><span class="dd-btn-txt">Role</span></h3>
				<button class="dd-btn dd-btn-selectable">
					<span class="dd-btn-text">*Role*</span>
					<span class="dd-span"></span>
				</button>
				<div class="champ-nav-dd-content" id="dd-role-div">
					<a href="#">All</a>
					<a href="#">Top</a>
					<a href="#">Jungle</a>
					<a href="#">Support</a>
				</div>
			</div>
			<div class="champ-nav-item champ-nav-item-selectable">
				<h3><span class="dd-btn-txt">Lane Opponent</span></h3>
				<button class="dd-btn dd-btn-selectable">
					<span class="dd-btn-text">*Champ*</span>
					<span class="dd-span"></span>
				</button>
				<div class="champ-nav-dd-content" id="dd-opponent-div">
					<a href="#">All</a>
					<a href="#">*Champ*</a>
					<a href="#">*Champ*</a>
					<a href="#">*Champ*</a>
				</div>
			</div>
		</div>

		<div>
			<div>
				<img src="\images\tierIcons_100x100\challenger_i.png" alt="">
			</div>
			<div>
				<h1>Name</h1>
			</div>
			<div>
				<h3>Rank</h3>
				<h3>LP</h3>
			</div>
		</div>

		<div>
			<div>
				<div>
					<p>View Drill Down Statistics Page</p>
				</div>
				<div>
					<!-- General Stats / Kill Types -->
				</div>
				<div>
					<!-- Most Played / Best On -->
				</div>
			</div>

			<div>
				<div>
					<div>
						<!-- Left Summary Table -->
					</div>
					<div>
						<!-- Right Summary Table -->
					</div>
				</div>

				<div>
					<!-- Recent Matches -->
				</div>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: 'Summoner',
	data () {
		return {
			summonerName: this.$route.params.summonerName
		};
	}
};
</script>

<style lang="scss">
	.champ-nav-wrapper {
		position: sticky;
		top: 25px;
		z-index: 4;
		text-align: center;
		margin: 0 auto;
	}

	.champ-nav-item {
		display: inline-block;
		width: 300px;
		border: 1px solid var(--champ-nav-item-border);
		color: var(--champ-nav-item-color);
		border-radius: 0;
		box-shadow: inset 0 1px 0 0 var(--champ-nav-item-box-shadow), inset 0 -1px 0 0 var(--champ-nav-item-box-shadow),
			inset 0 0 0 1px var(--champ-nav-item-box-shadow), inset 0 2px 4px 0 var(--champ-nav-item-box-shadow);
		-moz-box-shadow: inset 0 1px 0 0 var(--champ-nav-item-box-shadow), inset 0 -1px 0 0 var(--champ-nav-item-box-shadow),
			inset 0 0 0 1px var(--champ-nav-item-box-shadow), inset 0 2px 4px 0 var(--champ-nav-item-box-shadow);
		-webkit-box-shadow: inset 0 1px 0 0 var(--champ-nav-item-box-shadow),
			inset 0 -1px 0 0 var(--champ-nav-item-box-shadow), inset 0 0 0 1px var(--champ-nav-item-box-shadow),
			inset 0 2px 4px 0 var(--champ-nav-item-box-shadow);
		background-image: linear-gradient(to top, var(--champ-nav-item-background), var(--champ-nav-item-background-fade));

		h3 {
			color: var(--champ-nav-item-h3-color);
			font-size: 14px;
			line-height: 30px;
		}
	}

	.champ-nav-item.champ-nav-item-selectable:hover,
	.champ-nav-item.champ-nav-item-selectable:focus {
		background-image: var(--champ-nav-item-bg-hover);
	}

	.dd-btn {
		display: inline-block;
		position: relative;
		border-radius: 0;
		width: 100%;
		height: 30px;
		font-size: 16px;
		border: none;
		background: none;
		outline: none;
		color: var(--champ-nav-dd-btn-color);

		img {
			height: 25px;
			vertical-align: middle;
		}
	}

	.dd-btn.dd-btn-selectable::after {
		position: absolute;
		content: "";
		top: 35%;
		right: 15px;
		width: 0;
		height: 0;
		border: 8px solid transparent;
		border-color: var(--champ-nav-item-border) transparent transparent transparent;
	}

	.champ-nav-dd-content {
		display: none;
		position: absolute;
		overflow: auto;
		z-index: 1;
		background: var(--champ-nav-dd-content-bg);
		border: 1px solid var(--champ-nav-dd-content-border);
	}

	.champ-nav-dd-content a {
		color: var(--champ-nav-dd-content-border);
		padding: 12px 16px;
		text-decoration: none;
		display: block;
		min-width: 100px;
	}

	.champ-nav-dd-content a:hover {
		background: var(--champ-nav-dd-a-hover-bg);
	}

	.champ-nav-dd-content img {
		vertical-align: middle;
		height: 25px;
	}

	.champ-nav-item.champ-nav-item-selectable:hover .champ-nav-dd-content {
		display: block;
	}

	.champ-nav-item.champ-nav-item-selectable:hover #dd-champ-div,
	.champ-nav-item.champ-nav-item-selectable:hover #dd-opponent-div {
		display: grid;
		grid-template-columns: repeat(3, auto);
	}

	#dd-role-div {
		width: 298px;
	}

	#dd-champ-div {
		max-height: 325px;

		a {
			font-size: 16px;
			white-space: nowrap;
			text-align: left;
			padding: 2px 5px;
			height: 50px;

			img {
				width: 50px;
				height: 50px;
				padding: 0 5px 0 0;
			}
		}

		a:nth-child(1) {
			line-height: 54px;
			vertical-align: middle;
			text-align: center;
		}
	}

	#dd-opponent-div {
		max-height: 325px;

		a {
			font-size: 16px;
			white-space: nowrap;
			text-align: left;
			padding: 2px 5px;
			height: 50px;

			img {
				width: 50px;
				height: 50px;
				padding: 0 5px 0 0;
			}
		}

		a:nth-child(1) {
			line-height: 54px;
			vertical-align: middle;
			text-align: center;
		}
	}
</style>
